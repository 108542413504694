var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "100%, width: 100%" }, attrs: { id: _vm.id } },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            title: _vm.labelTitle,
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
          },
          on: { hidden: _vm.hidden },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$i18n.t("button.close")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _vm.alertMsg != null
            ? _c("AlertFeedback", {
                attrs: {
                  msg: _vm.alertMsg,
                  details: _vm.alertMsgDetails.list,
                  detailTitle: _vm.alertMsgDetails.title,
                  alertState: _vm.alertState,
                },
                on: { resetAlert: _vm.resetAlert },
              })
            : _vm._e(),
          _c("ListFilter", {
            attrs: { termOnly: "" },
            on: { applyFilter: _vm.applyFilter },
          }),
          _vm.allowManage
            ? _c(
                "div",
                { staticClass: "grid-toolbar border" },
                [
                  _vm.canAdd()
                    ? _c(
                        "span",
                        { attrs: { id: `BTN_ADD_${_vm.id}` } },
                        [
                          _c(
                            "b-btn",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.modalOpen(true)
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                style: { color: "var(--grid-toolbar-button)" },
                                attrs: { icon: ["far", "plus"] },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-popover",
                    {
                      attrs: {
                        target: `BTN_ADD_${_vm.id}`,
                        triggers: "hover",
                        placement: "top",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.add")) + " ")]
                  ),
                  _vm.canView()
                    ? _c(
                        "span",
                        { attrs: { id: `BTN_EDIT_${_vm.id}` } },
                        [
                          _c(
                            "b-btn",
                            {
                              attrs: { disabled: _vm.disableWhenEdit },
                              on: {
                                click: function ($event) {
                                  return _vm.modalOpen(false)
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "pen-to-square"] },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-popover",
                    {
                      attrs: {
                        target: `BTN_EDIT_${_vm.id}`,
                        triggers: "hover",
                        placement: "top",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.edit")) + " ")]
                  ),
                  _vm.canDelete()
                    ? _c(
                        "span",
                        { attrs: { id: `BTN_DELETE_${_vm.id}` } },
                        [
                          _c(
                            "b-btn",
                            {
                              attrs: { disabled: _vm.disableDelete },
                              on: { click: _vm.rowDelete },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "trash-can"] },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-popover",
                    {
                      attrs: {
                        target: `BTN_DELETE_${_vm.id}`,
                        triggers: "hover",
                        placement: "top",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.delete")) + " ")]
                  ),
                  _vm.canDelete()
                    ? _c(
                        "span",
                        { attrs: { id: `BTN_TOGGLE_DISPLAY_${_vm.id}` } },
                        [
                          _c(
                            "b-btn",
                            {
                              attrs: {
                                disabled:
                                  _vm.disableWhenNoSelection ||
                                  _vm.busyProcessing,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.rowToggleVisible()
                                },
                              },
                            },
                            [
                              _vm.nextVisibleToggleState
                                ? _c("font-awesome-icon", {
                                    attrs: { icon: ["far", "eye"] },
                                  })
                                : _c("font-awesome-icon", {
                                    attrs: { icon: ["far", "eye-slash"] },
                                  }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-popover",
                    {
                      attrs: {
                        target: `BTN_TOGGLE_DISPLAY_${_vm.id}`,
                        triggers: "hover",
                        placement: "top",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              _vm.nextVisibleToggleState
                                ? "button.apply_to_view"
                                : "button.remove_from_view"
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("ag-grid-vue", {
            staticClass: "ag-theme-balham selector-grid-height",
            staticStyle: { width: "100%" },
            attrs: {
              id: "user-grid",
              gridOptions: _vm.gridOptions,
              columnDefs: _vm.columnDefs,
              context: _vm.context,
              defaultColDef: _vm.defaultColDef,
              getRowId: (params) => params.data.uuId,
              overlayNoRowsTemplate: _vm.overlayNoRowsTemplate,
              overlayLoadingTemplate: _vm.overlayLoadingTemplate,
              pagination: "",
              paginationPageSizeSelector: false,
              paginationPageSize: 10,
              rowData: _vm.rowData,
              rowSelection: _vm.multiple ? "multiple" : "single",
              rowMultiSelectWithClick: "",
              sideBar: false,
              suppressDragLeaveHidesColumns: "",
              suppressCellFocus: "",
              suppressContextMenu: "",
              suppressMultiSort: "",
            },
            on: { "grid-ready": _vm.onGridReady },
          }),
        ],
        1
      ),
      _vm.taskGroupShow
        ? _c("TaskGroupModal", {
            attrs: {
              userId: _vm.userId,
              companyId: _vm.companyId,
              show: _vm.taskGroupShow,
              uuId: _vm.taskGroup.uuId,
              headerName: _vm.taskGroup.headerName,
              description: _vm.taskGroup.description,
              property: _vm.taskGroup.property,
              propertyOptions: _vm.propertyOptions,
              children: _vm.taskGroup.children,
              sharedVisibility: _vm.taskGroup.sharedVisibility,
              sharingMembers: _vm.taskGroup.sharingMembers,
              editors: _vm.taskGroup.editors,
              readOnly: _vm.taskGroup.readOnly,
              isDefault: _vm.taskGroup.isDefault,
            },
            on: {
              "update:show": function ($event) {
                _vm.taskGroupShow = $event
              },
              ok: _vm.taskGroupOk,
              defaultChanged: _vm.taskGroupDefaultChanged,
            },
          })
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("task.confirmation.title_delete"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.confirmDeleteOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ ok, cancel }) {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.confirmDeleteShow,
            callback: function ($$v) {
              _vm.confirmDeleteShow = $$v
            },
            expression: "confirmDeleteShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _c("p", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    _vm.selected.length > 1
                      ? "task.group.confirmation.delete_plural"
                      : "task.group.confirmation.delete"
                  )
                )
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }