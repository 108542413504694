<template>
  <div :id="id" style="height: 100%, width: 100%">
    <b-modal v-model="modalShow" size="lg" :title="labelTitle" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow" @hidden="hidden">

      <AlertFeedback v-if="alertMsg != null" :msg="alertMsg" :details="alertMsgDetails.list" :detailTitle="alertMsgDetails.title" :alertState="alertState" @resetAlert="resetAlert"/>

      <ListFilter termOnly @applyFilter="applyFilter"/>
      
      <div class="grid-toolbar border" v-if="allowManage">
        <span v-if="canAdd()" :id="`BTN_ADD_${id}`">
          <b-btn  @click="modalOpen(true)"><font-awesome-icon :icon="['far', 'plus']" :style="{ color: 'var(--grid-toolbar-button)' }"/></b-btn>  
        </span>
        <b-popover :target="`BTN_ADD_${id}`" triggers="hover" placement="top">
          {{ $t('button.add') }}
        </b-popover>
        <span v-if="canView()" :id="`BTN_EDIT_${id}`">
          <b-btn :disabled="disableWhenEdit" @click="modalOpen(false)"><font-awesome-icon :icon="['far', 'pen-to-square']"/></b-btn>  
        </span>
        <b-popover :target="`BTN_EDIT_${id}`" triggers="hover" placement="top">
          {{ $t('button.edit') }}
        </b-popover>
        <span  v-if="canDelete()" :id="`BTN_DELETE_${id}`">
          <b-btn :disabled="disableDelete" @click="rowDelete"><font-awesome-icon :icon="['far', 'trash-can']"/></b-btn>          
        </span>
        <b-popover :target="`BTN_DELETE_${id}`" triggers="hover" placement="top">
          {{ $t('button.delete') }}
        </b-popover>
        <span  v-if="canDelete()" :id="`BTN_TOGGLE_DISPLAY_${id}`">
          <b-btn :disabled="disableWhenNoSelection || busyProcessing" @click="rowToggleVisible()">
            <font-awesome-icon v-if="nextVisibleToggleState" :icon="['far', 'eye']"/>
            <font-awesome-icon v-else :icon="['far', 'eye-slash']"/>
          </b-btn>          
        </span>
        <b-popover :target="`BTN_TOGGLE_DISPLAY_${id}`" triggers="hover" placement="top">
          {{ $t(nextVisibleToggleState? 'button.apply_to_view': 'button.remove_from_view') }}
        </b-popover>
      </div>

      <ag-grid-vue style="width: 100%;" class="ag-theme-balham selector-grid-height" id="user-grid"
            :gridOptions="gridOptions"
            @grid-ready="onGridReady"
            :columnDefs="columnDefs"
            :context="context"
            :defaultColDef="defaultColDef"
            :getRowId="params => params.data.uuId"
            :overlayNoRowsTemplate="overlayNoRowsTemplate"
            :overlayLoadingTemplate="overlayLoadingTemplate"
            pagination
            :paginationPageSizeSelector="false"
            :paginationPageSize="10"
            :rowData="rowData"
            :rowSelection="multiple? 'multiple':'single'"
            rowMultiSelectWithClick
            :sideBar="false"
            suppressDragLeaveHidesColumns
            suppressCellFocus
            suppressContextMenu
            suppressMultiSort
            >
     </ag-grid-vue>

      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="danger" @click="cancel()">{{ $i18n.t('button.close') }}</b-button>
      </template>
    </b-modal>
    
    <TaskGroupModal v-if="taskGroupShow" 
      :userId="userId"
      :companyId="companyId"
      :show.sync="taskGroupShow" 
      :uuId="taskGroup.uuId" 
      :headerName="taskGroup.headerName"
      :description="taskGroup.description"
      :property="taskGroup.property"
      :propertyOptions="propertyOptions"
      :children="taskGroup.children"
      :sharedVisibility="taskGroup.sharedVisibility"
      :sharingMembers="taskGroup.sharingMembers"
      :editors="taskGroup.editors"
      :readOnly="taskGroup.readOnly"
      :isDefault="taskGroup.isDefault"
      @ok="taskGroupOk"
      @defaultChanged="taskGroupDefaultChanged"
    />
    

    <b-modal :title="$t('task.confirmation.title_delete')"
        v-model="confirmDeleteShow"
        @ok="confirmDeleteOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        <p>{{ $t(selected.length > 1? 'task.group.confirmation.delete_plural':'task.group.confirmation.delete') }}</p>
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { objectClone } from '@/helpers';
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue';
import alertStateEnum from '@/enums/alert-state';
import { taskGroupProfileService, viewProfileService } from '@/services';
import { columnDefSortFunc } from '@/views/management/script/common';

import ListFilter from '@/components/ListFilter/ListFilter';
import DetailLinkCellRenderer from '@/components/Aggrid/CellRenderer/DetailLink';
import VisibleCellRenderer from '@/components/Aggrid/CellRenderer/Visible';

export default {
  name: 'TaskGroupSelectorModal',
  components: {
    'ag-grid-vue': AgGridVue,
    TaskGroupModal: () => import('@/components/modal/TaskGroupModal'),
    ListFilter,
    AlertFeedback: () => import('@/components/AlertFeedback'),
    //aggrid cell renderer/editor/header component
    /* eslint-disable vue/no-unused-components */
    detailLinkCellRenderer: DetailLinkCellRenderer,
    visibleCellRenderer: VisibleCellRenderer
    /* eslint-enable vue/no-unused-components */
  },
  props: {
    companyId: {
      type: String,
      required: true
    },
    userId: {
      type: String,
      required: true
    },
    show: {
      type: Boolean,
      required: true
    },
    multiple: {
      type: Boolean,
      default: true
    },
    mode: {
      type: String,
      default: 'BOTH', // ['SELECT','MANAGE','BOTH']
    },
    title: {
      type: String,
      default: null
    },
    data: {
      type: Array,
      default: () => [] //a list of task group profile uuId
    },
    propertyOptions: {
      type: Array,
      default: () => []
    },
    feedback: {
      type: Object,
      default: () => { return {
          msg: null,
          alertState: null
        }
      }
    }
  },
  data: function() {
    return {
      id: `TASK_GROUP_LIST`,
      permissionName: 'TASK',
      gridOptions: null,
      gridApi: null,
      columnDefs: null,
      context: null,
      defaultColDef: null,
      rowData: null,
      
      modalShow: false,
      disableWhenEdit: true,
      disableWhenNoSelection: true,
      disableDelete: false,
      nextVisibleToggleState: true,
      busyProcessing: false,
      selected: [],

      taskGroup: {
        uuId: null,
        headerName: null,
        description: null,
        property: null,
        children: [],
        sharedVisibility: 'public',
        sharingMembers: [],
        editors: [],
        readOnly: false,
        isDefault: false
      },
      taskGroupShow: false,
      alertMsg: null,
      alertMsgDetails: { title: null, list: [] },
      alertState: alertStateEnum.SUCCESS,

      confirmDeleteShow: false,
      
      searchFilter: '',
      lastOpenColumnMenuParams: null
    };
  },
  beforeMount() {
    const self = this;
    const profileKey = 'task_group_selector_list';
    const getColumnDefs = (c) => {
      return {
        colId: c.colId
        , width: c.actualWidth
        , sort: c.sort != null? c.sort : null
        , sortIndex: c.sortIndex != null? c.sortIndex : null
      }
    }

    this.gridOptions = {
      
      onSelectionChanged: function(event) {
        const nodes = event.api.getSelectedNodes();
        self.selected.splice(0, self.selected.length, ...(nodes.map(i => i.data.uuId)));
        self.disableWhenEdit = nodes.length != 1;
        self.disableWhenNoSelection = nodes.length < 1;
        self.disableDelete = nodes.length < 1 || nodes.some(i => i.data.isDefault == true || i.data.editors.includes(self.userId) != true);
        self.updateNextVisibleState(nodes);
      },
      onColumnVisible: function(params) {
        let fromToolPanel = params.source == "toolPanelUi"
        if (fromToolPanel) {
          let colKey = params.column.colId;
          let columnMenuColumnIndex = params.api
            .getAllGridColumns()
            .findIndex(col => {
              return col === self.lastOpenColumnMenuParams.column;
            });

          params.api.moveColumns([colKey], columnMenuColumnIndex + 1);
        }
        const cols = params.api.getAllGridColumns().map(i => { 
          return { colId: i.colId, headerName: i.colDef.headerName, hide: i.colDef.hide, pinned: i.pinned }} )
        const columnState =  params.api.getColumnState();
        //get the actual hide value from columnState
        for (const col of columnState) {
          const found = cols.find(i => i.colId == col.colId)
          if (found) {
            found.hide = col.hide;
          }
        }
        cols.sort(columnDefSortFunc)
        for (const [index,c] of cols.entries()) {
          params.api.moveColumns([c.colId], index);
        }

        const columns = params.api.getAllDisplayedColumns();
        self.settings[profileKey] = columns.map(c => getColumnDefs(c));
        self.updateViewProfile();
      },
      postProcessPopup: params => {
        if ((params.type == 'columnMenu')) {
          self.lastOpenColumnMenuParams = params;
        }
      },
      onSortChanged: function(event) {
        const columns = event.api.getAllDisplayedColumns();
        self.settings[profileKey] = columns.map(c => getColumnDefs(c));
        self.updateViewProfile();
      },
      onDragStopped: function(event) {
        const columns = event.api.getAllDisplayedColumns();
        self.settings[profileKey] = columns.map(c => getColumnDefs(c));
        self.updateViewProfile();
      },
      onFirstDataRendered: function(event) {
        if (self.newToProfile != null && self.newToProfile == true) {
          self.newToProfile = null;
          event.api.sizeColumnsToFit();
          self.$nextTick(() => {
            const columns = event.api.getAllDisplayedColumns();
            self.settings[profileKey] = columns.map(c => getColumnDefs(c));
            self.updateViewProfile();
          })
        }
      }
    };
    const insensitiveCompareFunc = function(property) {
      return function(valueA, valueB, nodeA, nodeB) {
        const vA = nodeA.data[property].toLowerCase();
        const vB = nodeB.data[property].toLowerCase()
        if (vA ==vB) {
          return 0;
        }
        return vA > vB? 1 : -1;
      }
    }
    this.columnDefs = [
      {
        headerName: this.$t('task.group.field.displayName'),
        field: 'uuId',
        cellRenderer: 'detailLinkCellRenderer',
        checkboxSelection: true,
        lockVisible: true,
        minWidth: 200,
        hide: false,
        sort: 'asc',
        comparator: insensitiveCompareFunc('headerName'),
        getQuickFilterText: params => {
          return params.data ? params.data.headerName : '';
        }
      },
      {
        headerName: this.$t('task.group.field.property'),
        field: 'propertyDisplayName',
        hide: false,
        comparator: insensitiveCompareFunc('propertyDisplayName')
      },
      {
        headerName: this.$t('task.group.field.visible'),
        field: 'visible',
        maxWidth: 100,
        cellRenderer: 'visibleCellRenderer',
        hide: false,
      }
    ];
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      minWidth: 100,
      hide: true,
      menuTabs: ['columnsMenuTab'],
      getQuickFilterText: params => {
         return params.value;
      }
    };
    this.context = {
      componentParent: self
    };

    this.loadData();
  },
  mounted() {
    this.loadViewProfile();
  },
  created() {
    this.hasChanged = false;
    this.modalShow = this.show;
    this.settings = {};
  },
  beforeDestroy() {
    this.settings = null;
    this.gridApi = null;
  },
  watch: {
    feedback(newValue) {
      const msg = newValue.msg != null? newValue.msg: null;
      const alertState = newValue.alertState != null? newValue.alertState : null;
      this.resetAlert({ msg, alertState });
    }
  },
  computed: {
    allowManage() {
      return this.mode === 'MANAGE' || this.mode === 'BOTH';
    },
    overlayNoRowsTemplate() {
      return `<span class='grid-overlay'>${ this.$t('task.group.grid.no_data') }</span>`;
    },
    overlayLoadingTemplate() {
      return `<span class='grid-overlay'>${ this.$t('task.group.grid.loading') }</span>`;
    },
    labelTitle() {
      return this.title? this.title : this.$t('task.group.title_selector');
    },
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
    },
    updateNextVisibleState(nodes) {
      this.nextVisibleToggleState = nodes.some(i => { return i.data.visible != true });
    },
    async loadData() {
      const defaultTaskGroup = await taskGroupProfileService.getDefault(this.companyId, this.userId, { createIfNone: true })
      .then(result => {
        return result;
      })
      .catch(() => {
        return null;
      })

      const orgList = this.data;
      const list = await taskGroupProfileService.list(this.companyId, this.userId)
      .then(result => {
        return result.list;
      })
      .catch(() => {
        return null;
      });

      if (list == null) {
        this.resetAlert({ msg: this.$t('task.group.error.failed_to_load'), alertState: alertStateEnum.ERROR });
        this.rowData = [];
        this.gridApi.hideOverlay(); 
        return;
      }

      const data = list.map(i => { 
        const d = {
          uuId: i.uuId,
          propertyDisplayName: '',
          visible: false,
          ...i
        }

        if (orgList.findIndex(i => i == d.uuId) > -1) {
          d.visible = true;
        }

        const foundProp = this.propertyOptions.find(p => p.value == d.property);
        if (foundProp != null) {
          d.propertyDisplayName = foundProp.text;
        } else if (d.property != null) {
          d.propertyDisplayName = d.property;
        }

        if (defaultTaskGroup != null && defaultTaskGroup.uuId != null && d.uuId == defaultTaskGroup.uuId) {
          d.isDefault = true;
        } else {
          d.isDefault = false;
        }

        return d;
      })
      
      this.rowData = data;
      this.gridApi.hideOverlay();
    },
    modalOpen(isNew, uuId=null) {
      if(isNew) {
        this.taskGroup.uuId = null;
        this.taskGroup.headerName = null;
        this.taskGroup.description = null;
        this.taskGroup.property = 'estimatedDuration'; //Default
        this.taskGroup.children = null;
        this.taskGroup.sharedVisibility = 'public';
        this.taskGroup.sharingMembers = [];
        this.taskGroup.editors = [];
        this.taskGroup.readOnly = false;
        this.taskGroup.isDefault = false;
      } else {
        const id = uuId != null? uuId : this.selected[0];
        const foundGroup = this.gridApi.getRowNode(id).data;
        
        this.taskGroup.uuId = foundGroup.uuId;
        this.taskGroup.headerName = foundGroup.headerName;
        this.taskGroup.description = foundGroup.description;
        this.taskGroup.property = foundGroup.property;
        this.taskGroup.children = foundGroup.children != null? objectClone(foundGroup.children): null;
        this.taskGroup.sharedVisibility = foundGroup.sharedVisibility;
        this.taskGroup.sharingMembers = foundGroup.sharingMembers != null? objectClone(foundGroup.sharingMembers): null;
        this.taskGroup.editors = foundGroup.editors != null? objectClone(foundGroup.editors) : null;
        this.taskGroup.readOnly = foundGroup.editors.find(i => i == this.userId) == null;
        this.taskGroup.isDefault = foundGroup.isDefault == true;
      }
      this.taskGroupShow = true;
      this.resetAlert();
    },
    rowDelete() {
      this.confirmDeleteShow = true;
    },
    async confirmDeleteOk(){ 
      let api = null;
      if (this.gridApi != null) {
        api = this.gridApi;
      }
      if (api == null) {
        return;
      }

      this.hasChanged = true;
      const selectedData = api.getSelectedRows();

      let deletedRows = [];
      let failedRows = [];
      //API call to remove the selected row from db.
      await taskGroupProfileService.remove(selectedData.map(i => {
        return { uuId: i.uuId }
      }), this.userId)
      .then(response => {
        if (response.status == 207) {
          const data = response.data[response.data.jobCase];
          for (const [i, v] of data.entries()) {
            if (v.clue == 'ok') {
              deletedRows.push(selectedData[i]);
            } else {
              failedRows.push(selectedData[i]);
            }
          }
        } else {
          deletedRows = selectedData;
        }
      })
      .catch(() => {
        failedRows = selectedData;
      });
      
      //Update grid 
      api.applyTransaction({ remove: deletedRows });
      //Signal parent component about the latest visible task group
      this.$emit('changed', this.getVisibleTaskGroup(api));

      //Set alert feedback
      if (failedRows.length > 0) {
        let msg = failedRows.length > 1? this.$t('task.group.error.failed_to_delete_multi') : this.$t('task.group.error.failed_to_delete');
        let alertState = alertStateEnum.ERROR;
        if (deletedRows.length > 0) {
          msg = this.$t('task.group.delete_partial');
          alertState = alertState.WARNING;
        } 
        this.resetAlert({ msg, alertState
          , details: failedRows.map(i => `${i.headerName} (Property:${i.propertyDisplayName})`)
          , detailsTitle: this.$t('task.group.error.failed_to_delete_w_details')
        });
      } else {
        this.resetAlert({ 
          msg: deletedRows.length > 1? this.$t('task.group.delete_multi') : this.$t('task.group.delete')
          , alertState: alertStateEnum.SUCCESS
        });
      }
    },
    hidden() {
      this.selected.splice(0, this.selected.length);
      this.$emit('update:show', false);
      if (this.hasChanged) {
        this.$emit('reload');
      }
    },
    resetAlert({ msg=null, details=null, detailTitle=null, alertState=alertStateEnum.SUCCESS } = {}) {
      this.alertMsg = msg;
      this.alertState = alertState;
      this.alertMsgDetails.title = detailTitle;
      const list = this.alertMsgDetails.list;
      if (details != null && Array.isArray(details)) {
        list.splice(0, list.length, ...details);
      } else {
        list.splice(0, list.length);
      }
    },
    applyFilter(pattern) {
      this.gridApi.setQuickFilter(pattern);
    },
    taskGroupOk({ payload, isNew=false, hasDefaultChanged=false }) {
      let api = this.gridApi;
      if (api == null) {
        return;
      }
      
      this.hasChanged = true;

      const data = isNew? { uuId: payload.uuId, visible: false } : this.gridApi.getRowNode(payload.uuId).data;
      data.lastModified = payload.lastModified;
      data.headerName = payload.headerName;
      data.description = payload.description;
      data.children = objectClone(payload.children);
      data.property = payload.property;
      data.sharedVisibility = payload.sharedVisibility != null? payload.sharedVisibility : 'public';
      data.sharingMembers = payload.sharingMembers != null? payload.sharingMembers : [];
      data.editors = payload.editors != null? payload.editors : [];
      data.propertyDisplayName = payload.property != null? payload.property : '';
      
      const foundProp = this.propertyOptions.find(p => p.value == data.property);
      if (foundProp != null) {
        data.propertyDisplayName = foundProp.text;
      }

      if (isNew) {
        api.applyTransaction({ add: [data], addIndex: undefined });
      } else {
        api.applyTransaction({ update: [data] });
        api.refreshCells({ force: true }); //Fixed a bug where the name is not refreshed in the grid after update.
      }

      if (hasDefaultChanged) {
        this.taskGroupDefaultChanged(payload.uuId);
      }

      if (data.visible == true) {
        //Signal parent component about the latest visible TaskGroup
        this.$emit('changed', this.getVisibleTaskGroup(api, [data.uuId]));  
      }

      this.resetAlert({ msg: isNew? this.$t('task.group.create') : this.$t('task.group.update') });
    },
    taskGroupDefaultChanged(uuId) {
      const rowNodes = [];
      this.gridApi.forEachNode((rowNode /** , index */) => {
        if (rowNode.data == null) {
          return;
        }
        if (rowNode.data.uuId == uuId) {
          rowNode.data.isDefault = true;
        } else {
          rowNode.data.isDefault = false;
        }
        rowNodes.push(rowNode);
      });
      if (rowNodes.length > 0) {
        this.gridApi.applyTransaction({ update: rowNodes.map(i => i.data) });
        this.gridApi.refreshCells({ force: true, rowNodes });
        this.resetAlert({ msg: this.$t('task.group.update') });
      }
    },
    detailLinkLabel(params) {
      return `${params.data.headerName}${params.data.isDefault == true? ' (' + this.$t('task.group.default') + ')' : '' }`;
    },
    openDetail(id) {
      this.modalOpen(false, id);
    },
    rowToggleVisible() {
      let api = this.gridApi;
      if (api == null) {
        return;
      }
      this.busyProcessing = true;
      const selected = api.getSelectedNodes();
      for (const node of selected) {
        node.data.visible = this.nextVisibleToggleState;
      }
      this.updateNextVisibleState(selected);
      const itemToUpdate = selected.map(i => i.data);
      api.applyTransaction({ update: itemToUpdate });
      this.$nextTick(() => {
        this.$emit('changed', this.getVisibleTaskGroup(api, selected.map(i => i.data.uuId)));
      });
      this.hasChanged = true;
      this.busyProcessing = false;
    },
    getVisibleTaskGroup(api, modifiedList=null) {
      const visibleTaskGroups = [];
      let data = null;
      api.forEachNode((rowNode /** , index */) => {
        if (rowNode.data != null&& rowNode.data.visible == true) {
          data = rowNode.data;
          visibleTaskGroups.push({ 
            uuId: data.uuId
            , lastModified: data.lastModified
            , headerName: data.headerName
            , property: data.property
            , children: objectClone(data.children) 
          });
        }
      });
      data = null;//Reset variable

      if (modifiedList != null && modifiedList.length > 0) {
        for (const id of modifiedList) {
          const found = visibleTaskGroups.find(i => i.uuId == id);
          if (found == null) {
            continue;
          }
          found.triggeredByUserChange = true;
        }
      }
      return visibleTaskGroups;
    },
    updateViewProfile() {
      viewProfileService.update([this.settings], this.userId)
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    createViewProfile() {
      viewProfileService.create([this.settings],
                        this.userId).then((response) => {  
        const data = response.data[response.data.jobCase];
        this.settings.uuId = data[0].uuId;
        this.newToProfile = true;
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    loadViewProfile() {
      const self = this;
      this.$store.dispatch('data/viewProfileList', self.userId).then((value) => {  
        const profileData = value;
        if (profileData.length === 0) {
          self.createViewProfile();
        }
        else {
          self.settings = profileData[0];
         
          if (typeof self.settings.task_group_selector_list !== 'undefined') {
            self.loadColumnSettings(self, self.settings.task_group_selector_list);
          } else {
            self.newToProfile = true;
          }
        }
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    loadColumnSettings(data, columns) {
      // order the columns based upon the order in 'columns'
      let idx = 0;
      columns.forEach(function(col) {
        const index = data.columnDefs.findIndex((c) => c.field === col.colId);
        if (index !== -1) {
          data.columnDefs.splice(idx++, 0, data.columnDefs.splice(index, 1)[0]);
        }
      });
      
      for (const column of data.columnDefs) {
        const setting = columns.filter(c => c.colId === column.field);
        if (setting.length === 0) {
          column.hide = true;
        }
        else {
          column.hide = false;
          column.width = setting[0].width;
          column.sort = setting[0].sort;
          column.sortIndex = setting[0].sortIndex;
        }
      }
      
      if (data != null && data.gridApi != null) {
        data.gridApi.setGridOption('columnDefs', []);
        data.gridApi.setGridOption('columnDefs', data.columnDefs);
      }

      return false;
    },
  }
}


</script>